import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Grid } from "@material-ui/core";
import { fetchSocietyId } from "../../contextAPI/fetchSociety";
import { CheckCircle } from "@material-ui/icons";
import { getComplaintCategories } from "../../GlobalFunctions/GlobalFunctions";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    fontWeight: "bold",
    fontFamily: "Montserrat, sans-serif",
  },
  clearIndicator: {
    right: "-4px",
    marginTop: -8,
  },
}));

function ChooseCategoryDropDown(props) {
  const {
    setsearchKey,
    searchKey,
    setCategory,
    category,
    isMultiple,
    isDisabled,
  } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [categories, setcategories] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  const [pageForCategory, setpageForCategory] = useState(1);

  useEffect(() => {
    handleGetCategories();
  }, [pageForCategory, searchKey]);

  const handleGetCategories = async () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 40000);
    let sendData = {
      societyId: fetchSocietyId(),
      pageNumber: pageForCategory,
      searchKey,
    };
    const res = await getComplaintCategories(sendData);
    if (pageForCategory === 1) {
      setcategories(res?.complaintCategory);
      settotalCount(res?.totalPages);
    } else {
      setcategories((prev) => [...prev, ...res?.complaintCategory]);
      settotalCount(res?.totalPages);
    }
    setLoading(false);
  };

  const handleScroll = (e) => {
    const target = e.target;
    if (
      target.scrollTop + target.offsetHeight + 1 >= target.scrollHeight &&
      !loading &&
      totalCount !== categories?.length
    ) {
      setpageForCategory((prev) => prev + 1);
    }
  };

  return (
    <Grid container item xs={12}>
      <Autocomplete
        multiple={isMultiple}
        onChange={(e, value) => {
          setCategory(value);
        }}
        id="category-search"
        style={{
          width: "98%",
          backgroundColor: "#fff",
        }}
        freeSolo
        value={category}
        disabled={isDisabled}
        classes={classes}
        options={categories}
        autoHighlight
        getOptionLabel={(option) => `${option?._id ? option.categoryName : ""}`}
        renderOption={(props, option) => {
          return (
            <div>
              <li {...props} key={option._id}>
                {category?.length > 0 && category.includes(option) && (
                  <CheckCircle
                    style={{
                      color: "rgb(36 202 38)",
                      fontSize: "14px",
                      marginRight: "5px",
                    }}
                  />
                )}
                {option?.categoryName}
              </li>
            </div>
          );
        }}
        renderInput={(params) => {
          return (
            <TextField
              required
              {...params}
              onChange={(e) => {
                setpageForCategory(1);
                setsearchKey(e.target.value);
              }}
              onBlur={() => {
                setsearchKey("");
              }}
              label={loading ? "Loading..." : `Select Category (${totalCount})`}
              inputProps={{
                ...params.inputProps,
              }}
            />
          );
        }}
        ListboxProps={{
          onScroll: handleScroll,
        }}
      />
    </Grid>
  );
}

export default ChooseCategoryDropDown;
