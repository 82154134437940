import React, { useState, useContext } from "react";
import CategoryIcon from "@mui/icons-material/Category";
import SearchIcon from "@mui/icons-material/Search";
import * as api from "../../../api/index";
import useStyles from "./styles";
import { Grid, Typography } from "@material-ui/core";
import { useEffect } from "react";
import { Pagination } from "@mui/material";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import { LoadingOutlined } from "@ant-design/icons";
import AddSubCategory from "./AddSubCategory";
import SubCategoryTable from "./SubCategoryTable";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowLeft, Person } from "@material-ui/icons";
import { AuthContext } from "../../../contextAPI/context";
import ViewEscalationProfile from "./ViewEscalationProfile";
import AssignVendorToAll from "./AssignVendorToAll";
import { handleGetCheckModulePermission } from "../../../contextAPI/checkModulesPermission";
import { resolveErrorMessage } from "../../../services/service";
import { navigateToComplaintCategory } from "../../../Navigation/Navigations";
import { toast } from "react-toastify";

const ComplaintSubCategory = () => {
  const classes = useStyles();
  const { authState, authDispatch } = useContext(AuthContext);
  const { user, vendors, usermoduleswithpermissions, plant } = authState;
  const [allSubCategory, setallSubCategory] = useState([]);
  const [addComplaintSubCategory, setAddComplaintSubCategory] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState();
  const [search, setSearch] = useState("");
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [loader3, setloader3] = useState(false);
  const { id, categoryName } = useParams();
  const navigate = useNavigate();
  const [subCategoriEdited, setsubCategoriEdited] = useState([]);
  const [isSubcategoryEdit, setisSubcategoryEdit] = useState(false);
  const [showEscalationProfile, setshowEscalationProfile] = useState(false);
  const [escalationProfile, setescalationProfile] = useState([]);
  const [assignToAll, setassignToAll] = useState(false);
  const [isReadWrite, setIsReadWrite] = useState(false);

  useEffect(async () => {
    if (usermoduleswithpermissions) {
      setIsReadWrite(
        await handleGetCheckModulePermission(
          usermoduleswithpermissions,
          "Category"
        )
      );
    }
  }, [usermoduleswithpermissions]);

  const handlePageChange = async (event, value) => {
    setPage(value);
  };

  const getComplaintSubCategory = async (search) => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      let sendData = {
        societyId: fetchSocietyId(),
        categoryId: id,
        pageNumber: page,
        searchKey: search,
      };
      const res = await api.getSubComplaintCategory(sendData);
      if (res?.data?.success) {
        setCount(res.data.sub_category.count);
        setallSubCategory(res.data?.sub_category?.allsubcategories);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      resolveErrorMessage(error);
    }
  };

  const handleGetEscalationProfile = async () => {
    setloader3(true);
    setTimeout(() => {
      setloader3(false);
    }, 40000);
    try {
      let sendData = {
        societyId: fetchSocietyId(),
        categoryId: id,
      };
      const res = await api.getEscalationProfile(sendData);
      if (res?.data?.success) {
        setescalationProfile(res?.data?.profile);
        setloader3(false);
      }
    } catch (error) {
      setloader3(false);
      resolveErrorMessage(error);
    }
  };

  useEffect(() => {
    if (search?.length >= 3) {
      const handleDebounce = setTimeout(() => {
        getComplaintSubCategory(search);
      }, 500);

      return () => {
        clearTimeout(handleDebounce);
      };
    } else if (search === "") {
      getComplaintSubCategory("");
    }
  }, [search, 500, page]);

  return (
    <div
      className={classes.root}
      style={{
        filter: loader || loader2 ? "blur(1px)" : "",
        pointerEvents: loader || loader2 ? "none" : "",
        overflow: loader || loader2 ? "hidden" : "auto",
        height: "100vh",
      }}
    >
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid item lg={12} sm={12} xs={12}>
          <div className="card" style={{ borderRadius: 10 }}>
            <div className="card-body" style={{ borderRadius: 10 }}>
              <Grid container style={{ justifyContent: "space-between" }}>
                <Grid item lg={6}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 18,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                    }}
                  >
                    Complaint{" "}
                    <span
                      onClick={() => {
                        navigate(navigateToComplaintCategory({}));
                      }}
                      style={{ cursor: "pointer" }}
                    >{` > Category `}</span>{" "}
                    <span style={{ color: "#000" }}>{` > Subcategory `}</span>
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  lg={6}
                  style={{ justifyContent: "flex-end" }}
                >
                  <div
                    class="card"
                    style={{
                      height: 30,
                      boxShadow: "0 3px 6px #4a6078",
                      width: "180px",
                      backgroundColor: "#2f45c5",
                      color: "#ffffff",
                      cursor: "pointer",
                      marginRight: 15,
                    }}
                  >
                    <div class="card-body ">
                      <p
                        class="card-text"
                        style={{
                          fontSize: "11px",
                          fontWeight: "bold",
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "-12px",
                          marginBottom: "20px",
                          textAlign: "center",
                        }}
                        onClick={() => {
                          handleGetEscalationProfile();
                          setshowEscalationProfile(true);
                        }}
                      >
                        <Person
                          style={{
                            marginRight: 10,
                            fontSize: "15px",
                          }}
                        />
                        Escalation Profiles
                      </p>
                    </div>
                  </div>
                  {isReadWrite && (
                    <div
                      class="card"
                      style={{
                        height: 30,
                        boxShadow: "0 3px 6px #4a6078",
                        width: "160px",
                        backgroundColor: "#2f45c5",
                        color: "#ffffff",
                        cursor: "pointer",
                        marginRight: 15,
                      }}
                    >
                      <div class="card-body ">
                        <p
                          class="card-text"
                          style={{
                            fontSize: "11px",
                            fontWeight: "bold",
                            fontFamily: "Montserrat, sans-serif",
                            marginTop: "-12px",
                            marginBottom: "20px",
                            textAlign: "center",
                          }}
                          onClick={() => {
                            setAddComplaintSubCategory(true);
                          }}
                        >
                          <CategoryIcon
                            style={{
                              marginRight: 10,
                              fontSize: "15px",
                            }}
                          />
                          Add Subcategory
                        </p>
                      </div>
                    </div>
                  )}
                  <div
                    class="card"
                    style={{
                      height: 30,
                      boxShadow: "0 3px 6px #4a6078",
                      width: "90px",
                      backgroundColor: "#2f45c5",
                      color: "#ffffff",
                      cursor: "pointer",
                      marginRight: 15,
                    }}
                    onClick={() => {
                      navigate(navigateToComplaintCategory({}));
                    }}
                  >
                    <div class="card-body ">
                      <p
                        class="card-text"
                        style={{
                          fontSize: "11px",
                          fontWeight: "bold",
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "-12px",
                          marginBottom: "20px",
                          textAlign: "center",
                        }}
                        onClick={() => {
                          navigate(navigateToComplaintCategory({}));
                        }}
                      >
                        <ArrowLeft />
                        Back
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid
                container
                style={{ marginTop: 30, justifyContent: "space-between" }}
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: 15,
                    fontFamily: "Montserrat, sans-serif",
                    color: "#6c757d",
                    marginLeft: 12,
                  }}
                >
                  Category Name :&nbsp;
                  <span style={{ color: "#000", fontSize: 17 }}>
                    {categoryName}
                  </span>
                </Typography>
                <div
                  class="box"
                  style={{
                    minWidth: "250px",
                    marginLeft: 10,
                    border: "1px solid #E1E1E1",
                    borderRadius: 10,
                  }}
                >
                  <input
                    onChange={(e) => {
                      setSearch(e.target.value);
                      setPage(1);
                    }}
                    value={search}
                    type="text"
                    placeholder="search..."
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        if (search === "") {
                          toast.error("Please enter search input first");
                        } else {
                          getComplaintSubCategory();
                        }
                      }
                    }}
                  />
                  <div className="searchIcon">
                    <SearchIcon
                      onClick={() => {
                        if (search === "") {
                          toast.error("Please enter search input first");
                        } else {
                          getComplaintSubCategory();
                        }
                      }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid container>
                <SubCategoryTable
                  allSubCategory={allSubCategory}
                  page={page}
                  setisSubcategoryEdit={setisSubcategoryEdit}
                  setsubCategoriEdited={setsubCategoriEdited}
                  setshowEscalationProfile={setshowEscalationProfile}
                  handleGetEscalationProfile={handleGetEscalationProfile}
                  setassignToAll={setassignToAll}
                  isReadWrite={isReadWrite}
                  subCategoriEdited={subCategoriEdited}
                  getComplaintSubCategory={getComplaintSubCategory}
                  user={user}
                  isPlant={plant}
                />
              </Grid>
              {allSubCategory?.length === 0 && !loader && (
                <Grid container style={{ justifyContent: "center" }}>
                  <Typography
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                      fontSize: 17,
                      marginTop: 7,
                      fontWeight: "bolder",
                    }}
                  >
                    No Complaint Subcategory
                  </Typography>
                </Grid>
              )}
              {loader2 && (
                <Typography
                  style={{
                    marginBottom: 10,
                    textAlign: "center",
                    fontSize: 15,
                    color: "#2f45c5",
                  }}
                >
                  page is loading.... <LoadingOutlined />
                </Typography>
              )}
              {allSubCategory?.length > 0 && (
                <Grid container style={{ justifyContent: "center" }}>
                  <Pagination
                    className={classes.pagination}
                    count={count}
                    color="primary"
                    page={page}
                    onChange={handlePageChange}
                  />
                </Grid>
              )}
              <AddSubCategory
                addComplaintSubCategory={addComplaintSubCategory}
                setAddComplaintSubCategory={setAddComplaintSubCategory}
                getComplaintSubCategory={getComplaintSubCategory}
                vendors={vendors}
                categoryId={id}
                isSubcategoryEdit={isSubcategoryEdit}
                setisSubcategoryEdit={setisSubcategoryEdit}
                subCategoriEdited={subCategoriEdited}
              />
            </div>
          </div>
        </Grid>
      </main>
      {loader && (
        <Grid
          style={{
            justifyContent: "center",
            position: "absolute",
            top: "50%",
            left: "55%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <LoadingOutlined
            style={{ fontSize: 60, color: "#2f45c5", marginLeft: 18 }}
          />
          <Typography
            style={{
              color: "#2f45c5",
              textAlign: "center",
              fontSize: 18,
              marginLeft: -23,
              marginTop: 6,
            }}
          >
            Loading Subcategory...
          </Typography>
        </Grid>
      )}
      <ViewEscalationProfile
        showEscalationProfile={showEscalationProfile}
        setshowEscalationProfile={setshowEscalationProfile}
        escalationProfile={escalationProfile}
        loader3={loader3}
        categoryId={id}
        handleGetEscalationProfile={handleGetEscalationProfile}
        isReadWrite={isReadWrite}
      />
      <AssignVendorToAll
        assignToAll={assignToAll}
        setassignToAll={setassignToAll}
        vendors={vendors}
        categoryId={id}
        getComplaintSubCategory={getComplaintSubCategory}
      />
    </div>
  );
};

export default ComplaintSubCategory;
