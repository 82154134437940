import { Grid, Typography, Tooltip } from "@material-ui/core";
import {
  Apartment,
  Domain,
  Home,
  KeyboardBackspaceRounded,
} from "@material-ui/icons";
import { Circle, ContentCopy } from "@mui/icons-material";
import { Divider } from "@mui/material";
import React, { useRef } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import {
  handleFlatName,
  handleFloorName,
  handlePropertyName,
} from "../../../contextAPI/NamingConvention";
import { navigateToComplaintTableRoute } from "../../../Navigation/Navigations";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactToPrint from "react-to-print";
import PrintComplaintDetails from "../Invoice/PrintComplaintDetails";
import { getStatusClass } from "../../../GlobalFunctions/GlobalFunctions";
import moment from "moment";

const ComplaintDetailsCard = (props) => {
  const navigate = useNavigate();
  const { complaint, complaintType, setSlideshow, isPlant } = props;
  const componentRef = useRef();

  return (
    <>
      <Grid container style={{ justifyContent: "space-between" }}>
        <Typography
          style={{
            fontWeight: 500,
            fontSize: 21,
            fontFamily: "Poppins",
            color: "#000000",
          }}
        >
          COMPLAINT DETAILS
        </Typography>
        <div
          className="back-button"
          onClick={() => {
            navigate(navigateToComplaintTableRoute({ complaintType }));
          }}
        >
          <div>
            <p className="back-button-text">
              <KeyboardBackspaceRounded
                style={{
                  marginRight: 10,
                  fontSize: "15px",
                }}
              />
              &nbsp;Back
            </p>
          </div>
        </div>
      </Grid>
      <Grid
        container
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 10,
          marginBottom: "2rem",
        }}
      >
        <Typography
          className="text-capitalize"
          style={{
            fontWeight: 500,
            fontSize: 24,
            fontFamily: "Poppins",
            color: "#000000",
          }}
        >
          {complaint?.complaintTitle}
        </Typography>
        <Typography
          className="text-capitalize"
          style={{
            fontWeight: 500,
            fontSize: 21,
            fontFamily: "Poppins",
            color: "#979797",
          }}
        >
          {complaint?.description}
        </Typography>
      </Grid>
      <Grid container xs={12} lg={12} md={12} style={{ marginTop: "10px" }}>
        <Grid item xs={7} lg={7} md={7} style={{ paddingRight: "10px" }}>
          <div
            style={{
              border: "1px solid #E1E1E1",
              borderRadius: 10,
              padding: "8px 25px",
            }}
          >
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.667 4V9.33333C18.667 9.68696 18.8075 10.0261 19.0575 10.2761C19.3076 10.5262 19.6467 10.6667 20.0003 10.6667H25.3337M18.667 4H9.33366C8.62641 4 7.94814 4.28095 7.44804 4.78105C6.94794 5.28115 6.66699 5.95942 6.66699 6.66667V25.3333C6.66699 26.0406 6.94794 26.7189 7.44804 27.219C7.94814 27.719 8.62641 28 9.33366 28H22.667C23.3742 28 24.0525 27.719 24.5526 27.219C25.0527 26.7189 25.3337 26.0406 25.3337 25.3333V10.6667M18.667 4L25.3337 10.6667M12.0003 22.6667H20.0003M12.0003 17.3333H20.0003"
                    stroke="#03045E"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <Typography
                  style={{
                    textAlign: "center",
                    fontFamily: "Poppins",
                    color: "#03045E",
                    fontSize: 20,
                  }}
                >
                  Complaint Information
                  <Divider
                    style={{
                      marginBottom: 10,
                      backgroundColor: "#03045E",
                      width: "100%",
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                  />
                </Typography>
              </div>
              <div>
                <ReactToPrint
                  trigger={() => (
                    <Typography
                      style={{
                        cursor: "pointer",
                        ffontFamily: "Poppins",
                        textDecoration: "underline",
                        color: "#03045E",
                        fontWeight: 400,
                        fontSize: 18,
                      }}
                    >
                      Print details
                    </Typography>
                  )}
                  content={() => componentRef.current}
                />
                <div style={{ display: "none" }}>
                  <PrintComplaintDetails
                    ref={componentRef}
                    complaint={complaint}
                    isPlant={isPlant}
                  />
                </div>
              </div>
            </div>

            <Grid item>
              <div className="row" style={{ marginTop: 8 }}>
                <div className="col-md-6">
                  <Typography
                    style={{
                      fontSize: 18,
                      fontFamily: "Poppins",
                      color: "#979797",
                      fontWeight: 400,
                    }}
                  >
                    1.&nbsp; &nbsp; Complaint Category :
                  </Typography>
                </div>
                <div className="col-md-6">
                  <Typography
                    style={{
                      fontSize: 18,
                      fontFamily: "Poppins",
                      color: "#000000",
                      fontWeight: 400,
                    }}
                  >
                    {complaint?.category?.categoryName}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item>
              <div className="row" style={{ marginTop: 8 }}>
                <div className="col-md-6">
                  <Typography
                    style={{
                      fontSize: 18,
                      fontFamily: "Poppins",
                      color: "#979797",
                      fontWeight: 400,
                    }}
                  >
                    2.&nbsp; &nbsp;Sub Category :
                  </Typography>
                </div>
                <div className="col-md-6">
                  <Typography
                    style={{
                      fontSize: 18,
                      fontFamily: "Poppins",
                      color: "#000000",
                      fontWeight: 400,
                    }}
                  >
                    {complaint?.subcomplaintCategory?.subcategoryName}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item>
              <div className="row" style={{ marginTop: 8 }}>
                <div className="col-md-6">
                  <Typography
                    style={{
                      fontSize: 18,
                      fontFamily: "Poppins",
                      color: "#979797",
                      fontWeight: 400,
                    }}
                  >
                    3.&nbsp; &nbsp;Complaint ID :{" "}
                  </Typography>
                </div>
                <div className="col-md-6">
                  <Typography
                    style={{
                      fontSize: 18,
                      fontFamily: "Poppins",
                      color: "#000000",
                      fontWeight: 400,
                    }}
                  >
                    {complaint?.complaintNumber}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item>
              <div className="row" style={{ marginTop: 8 }}>
                <div className="col-md-6">
                  <Typography
                    style={{
                      fontSize: 18,
                      fontFamily: "Poppins",
                      color: "#979797",
                      fontWeight: 400,
                    }}
                  >
                    4.&nbsp; &nbsp;Status :{" "}
                  </Typography>
                </div>
                <div className="col-md-6">
                  <span
                    className={`complaint-status ${getStatusClass(
                      complaint?.status
                    )}`}
                  >
                    {complaint?.status}
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item>
              {/* {user?._id === complaint?.complainer?._id && ( */}
              <div className="row" style={{ marginTop: 8 }}>
                <div className="col-md-6">
                  <Typography
                    style={{
                      fontSize: 18,
                      fontFamily: "Poppins",
                      color: "#979797",
                      fontWeight: 400,
                    }}
                  >
                    5.&nbsp; &nbsp;Resolve OTP :{" "}
                  </Typography>
                </div>
                <div className="col-md-6">
                  <Typography
                    style={{
                      fontSize: 18,
                      fontFamily: "Poppins",
                      color: "#000000",
                      fontWeight: 400,
                    }}
                  >
                    {complaint?.resolveOtp}
                    <CopyToClipboard
                      title="Copy Resolve OTP"
                      text={complaint?.resolveOtp}
                      onCopy={() => {
                        toast.success("Copy to Clipboard", {
                          position: "top-right",
                          autoClose: 500,
                          hideProgressBar: false,
                          closeOnClick: true,
                        });
                      }}
                    >
                      <ContentCopy
                        style={{
                          fontSize: 17,
                          marginLeft: 6,
                          color: "#2A265F",
                          cursor: "pointer",
                        }}
                      />
                    </CopyToClipboard>
                  </Typography>
                </div>
              </div>
              {/* // )} */}
            </Grid>
            <Grid item>
              <div className="row" style={{ marginTop: 8 }}>
                <div className="col-md-6">
                  <Typography
                    style={{
                      fontSize: 18,
                      fontFamily: "Poppins",
                      color: "#979797",
                      fontWeight: 400,
                    }}
                  >
                    6.&nbsp; &nbsp;Auto Escalation :
                  </Typography>
                </div>
                <div className="col-md-6">
                  <Typography
                    style={{
                      fontSize: 18,
                      fontFamily: "Poppins",
                      color: "#000000",
                      fontWeight: 400,
                    }}
                  >
                    {complaint?.autoEscalate ? "YES" : "NO"}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item>
              <div className="row" style={{ marginTop: 8 }}>
                <div className="col-md-6">
                  <Typography
                    style={{
                      fontSize: 18,
                      fontFamily: "Poppins",
                      color: "#979797",
                      fontWeight: 400,
                    }}
                  >
                    7.&nbsp; &nbsp;Priority :
                  </Typography>
                </div>
                <div className="col-md-6">
                  <Typography
                    style={{
                      fontSize: 18,
                      fontFamily: "Poppins",
                      color:
                        complaint?.priority === "high" ? "#03045E" : "#F31D2F",
                      fontWeight: 400,
                    }}
                  >
                    <Circle style={{ fontSize: 15, marginRight: 6 }} />
                    {complaint?.priority === "high" ? "HIGH" : "LOW"}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item>
              <div className="row" style={{ marginTop: 8 }}>
                <div className="col-md-6">
                  <Typography
                    style={{
                      fontSize: 18,
                      fontFamily: "Poppins",
                      color: "#979797",
                      fontWeight: 400,
                    }}
                  >
                    8.&nbsp; &nbsp;Final Deadline :
                  </Typography>
                </div>
                <div className="col-md-6">
                  <Typography
                    style={{
                      fontSize: 18,
                      fontFamily: "Poppins",
                      color: "#000000",
                      fontWeight: 400,
                    }}
                  >
                    {complaint?.finalDeadline
                      ? moment(complaint.finalDeadline).format(
                          "DD/MM/YYYY - h:mm A"
                        )
                      : "-"}
                  </Typography>
                </div>
              </div>
            </Grid>
            {isPlant && (
              <>
                <Grid item>
                  <div className="row" style={{ marginTop: 8 }}>
                    <div className="col-md-6">
                      <Typography
                        style={{
                          fontSize: 18,
                          fontFamily: "Poppins",
                          color: "#979797",
                          fontWeight: 400,
                        }}
                      >
                        9.&nbsp; &nbsp;Type Of Complaint :
                      </Typography>
                    </div>
                    <div className="col-md-6">
                      <Typography
                        style={{
                          fontSize: 18,
                          fontFamily: "Poppins",
                          color: "#000000",
                          fontWeight: 400,
                        }}
                      >
                        {complaint?.subsubCategory
                          ? complaint?.subsubCategory?.name
                          : "-"}
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid item>
                  <div className="row" style={{ marginTop: 8 }}>
                    <div className="col-md-6">
                      <Typography
                        style={{
                          fontSize: 18,
                          fontFamily: "Poppins",
                          color: "#979797",
                          fontWeight: 400,
                        }}
                      >
                        10.&nbsp; &nbsp;Area :
                      </Typography>
                    </div>
                    <div className="col-md-6">
                      <Typography
                        style={{
                          fontSize: 18,
                          fontFamily: "Poppins",
                          color: "#000000",
                          fontWeight: 400,
                        }}
                      >
                        {complaint?.area ? complaint?.area?.name : "-"}
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid item>
                  <div className="row" style={{ marginTop: 8 }}>
                    <div className="col-md-6">
                      <Typography
                        style={{
                          fontSize: 18,
                          fontFamily: "Poppins",
                          color: "#979797",
                          fontWeight: 400,
                        }}
                      >
                        11.&nbsp; &nbsp;Subarea :
                      </Typography>
                    </div>
                    <div className="col-md-6">
                      <Typography
                        style={{
                          fontSize: 18,
                          fontFamily: "Poppins",
                          color: "#000000",
                          fontWeight: 400,
                        }}
                      >
                        {complaint?.subArea ? complaint?.subArea?.name : "-"}
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid item>
                  <div className="row" style={{ marginTop: 8 }}>
                    <div className="col-md-6">
                      <Typography
                        style={{
                          fontSize: 18,
                          fontFamily: "Poppins",
                          color: "#979797",
                          fontWeight: 400,
                        }}
                      >
                        12.&nbsp; &nbsp;Zone :
                      </Typography>
                    </div>
                    <div className="col-md-6">
                      <Typography
                        style={{
                          fontSize: 18,
                          fontFamily: "Poppins",
                          color: "#000000",
                          fontWeight: 400,
                        }}
                      >
                        {complaint?.zone ? complaint?.zone : "-"}
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </>
            )}

            <Grid>
              <div>
                <Typography
                  style={{
                    textAlign: "center",
                    color: "#000",
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    marginTop: 15,
                    opacity: 0.7,
                    textTransform: "uppercase",
                  }}
                >
                  Address Information
                </Typography>
                <Divider
                  style={{
                    marginBottom: 10,
                    backgroundColor: "#000000",
                    width: "100%",
                    alignItems: "center",
                    alignSelf: "center",
                  }}
                />

                {complaint?.complainer?.property?.name ? (
                  <Grid container>
                    <Grid item xs={12}>
                      <div className="row" style={{ marginTop: 5 }}>
                        <div className="col-md-6">
                          <Typography
                            style={{
                              fontSize: 18,
                              fontFamily: "Poppins",
                              color: "#979797",
                              fontWeight: 400,
                            }}
                          >
                            1.&nbsp;
                            <Apartment
                              style={{ fontSize: "18px" }}
                            /> &nbsp; {handlePropertyName()} :
                          </Typography>
                        </div>
                        <div className="col-md-6">
                          <Typography
                            style={{
                              fontSize: 18,
                              fontFamily: "Poppins",
                              color: "#000000",
                              fontWeight: 400,
                            }}
                          >
                            {complaint?.complainer?.property?.name}
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="row" style={{ marginTop: 5 }}>
                        <div className="col-md-6">
                          <Typography
                            style={{
                              fontSize: 18,
                              fontFamily: "Poppins",
                              color: "#979797",
                              fontWeight: 400,
                            }}
                          >
                            2.&nbsp;
                            <Domain style={{ fontSize: "18px" }} /> &nbsp;
                            {handleFloorName()} :
                          </Typography>
                        </div>
                        <div className="col-md-6">
                          <Typography
                            style={{
                              fontSize: 18,
                              fontFamily: "Poppins",
                              color: "#000000",
                              fontWeight: 400,
                            }}
                          >
                            {complaint?.floor?.name}
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="row" style={{ marginTop: 5 }}>
                        <div className="col-md-6">
                          <Typography
                            style={{
                              fontSize: 18,
                              fontFamily: "Poppins",
                              color: "#979797",
                              fontWeight: 400,
                            }}
                          >
                            3.&nbsp;
                            <Home style={{ fontSize: "18px" }} /> &nbsp;
                            {handleFlatName()} :
                          </Typography>
                        </div>
                        <div className="col-md-6">
                          <Typography
                            style={{
                              fontSize: 18,
                              fontFamily: "Poppins",
                              color: "#000000",
                              fontWeight: 400,
                            }}
                          >
                            {complaint?.complainer?.flat?.number}
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container>
                    <Grid item xs={12}>
                      <div className="row" style={{ marginTop: 5 }}>
                        <div className="col-md-6">
                          <Typography
                            style={{
                              fontSize: 18,
                              fontFamily: "Poppins",
                              color: "#979797",
                              fontWeight: 400,
                            }}
                          >
                            1.&nbsp;
                            <Apartment style={{ fontSize: "18px" }} /> &nbsp;
                            {handlePropertyName()} :
                          </Typography>
                        </div>
                        <div className="col-md-6">
                          <Typography
                            style={{
                              fontSize: 18,
                              fontFamily: "Poppins",
                              color: "#000000",
                              fontWeight: 400,
                            }}
                          >
                            {complaint?.property?.name}
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="row" style={{ marginTop: 5 }}>
                        <div className="col-md-6">
                          <Typography
                            style={{
                              fontSize: 18,
                              fontFamily: "Poppins",
                              color: "#979797",
                              fontWeight: 400,
                            }}
                          >
                            2.&nbsp;
                            <Domain style={{ fontSize: "18px" }} /> &nbsp;
                            {handleFloorName()} :
                          </Typography>
                        </div>
                        <div className="col-md-6">
                          <Typography
                            style={{
                              ffontSize: 18,
                              fontFamily: "Poppins",
                              color: "#000000",
                              fontWeight: 400,
                            }}
                          >
                            {complaint?.floor?.name}
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="row" style={{ marginTop: 5 }}>
                        <div className="col-md-6">
                          <Typography
                            style={{
                              fontSize: 18,
                              fontFamily: "Poppins",
                              color: "#979797",
                              fontWeight: 400,
                            }}
                          >
                            3.&nbsp;
                            <Home style={{ fontSize: "18px" }} /> &nbsp;
                            {handleFlatName()}:
                          </Typography>
                        </div>
                        <div className="col-md-6">
                          <Typography
                            style={{
                              fontSize: 18,
                              fontFamily: "Poppins",
                              color: "#000000",
                              fontWeight: 400,
                            }}
                          >
                            {complaint?.flat?.number}
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                )}

                {complaint?.complaintType !== "personal" && (
                  <Grid item xs={12}>
                    <div className="row" style={{ marginTop: 5 }}>
                      <div className="col-md-6">
                        <Typography
                          style={{
                            fontSize: 15,
                            fontFamily: "Montserrat, sans-serif",
                            color: "#000",
                            fontWeight: "bold",
                          }}
                        >
                          &nbsp; &nbsp;Complaint for Common Area Address :{" "}
                        </Typography>
                      </div>
                      <div className="col-md-6">
                        <Typography
                          style={{
                            fontSize: 15,
                            fontFamily: "Montserrat, sans-serif",
                            color: "#6c757d",
                            fontWeight: "bold",
                          }}
                        >
                          {complaint?.complaintAddress}
                        </Typography>
                      </div>
                    </div>
                  </Grid>
                )}
              </div>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={5} lg={5} md={5} style={{ paddingLeft: "10px" }}>
          <div
            style={{
              border: "1px solid #E1E1E1",
              borderRadius: 10,
              padding: "8px 25px",
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "-10%",
              }}
            >
              <img
                class="round"
                src={
                  complaint?.complainer?.image
                    ? complaint?.complainer?.image
                    : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                }
                alt={complaint?.complainer?.name}
                height="70px"
                width="70px"
                style={{
                  marginTop: 6,
                  borderRadius: "50%",
                  border: "1px solid #E1E1E1",
                }}
              />
            </div>
            <Grid item>
              <div className="row" style={{ marginTop: 8 }}>
                <div className="col-md-6">
                  <Typography
                    style={{
                      fontSize: 18,
                      fontFamily: "Poppins",
                      color: "#979797",
                      fontWeight: 400,
                    }}
                  >
                    1.&nbsp; &nbsp; Name :{" "}
                  </Typography>
                </div>
                <div className="col-md-6">
                  <Typography
                    style={{
                      fontSize: 18,
                      fontFamily: "Poppins",
                      color: "#000000",
                      fontWeight: 400,
                    }}
                  >
                    {complaint?.complainer?.name}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item>
              <div className="row" style={{ marginTop: 8 }}>
                <div className="col-md-6">
                  <Typography
                    style={{
                      fontSize: 18,
                      fontFamily: "Poppins",
                      color: "#979797",
                      fontWeight: 400,
                    }}
                  >
                    2.&nbsp; &nbsp; Phone :{" "}
                  </Typography>
                </div>
                <div className="col-md-6">
                  <Typography
                    style={{
                      fontSize: 18,
                      fontFamily: "Poppins",
                      color: "#000000",
                      fontWeight: 400,
                    }}
                  >
                    {complaint?.complainer?.phone
                      ? complaint?.complainer?.phone
                      : ""}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item>
              <div className="row" style={{ marginTop: 8 }}>
                <div className="col-md-6">
                  <Typography
                    style={{
                      fontSize: 18,
                      fontFamily: "Poppins",
                      color: "#979797",
                      fontWeight: 400,
                    }}
                  >
                    3.&nbsp; &nbsp; Email :{" "}
                  </Typography>
                </div>
                <div className="col-md-6">
                  <Tooltip
                    title={complaint?.complainer?.email}
                    placement="top"
                    arrow
                  >
                    <Typography
                      style={{
                        fontSize: 18,
                        fontFamily: "Poppins",
                        color: "#000000",
                        fontWeight: 400,
                      }}
                    >
                      {complaint?.complainer?.email?.length > 22
                        ? complaint?.complainer?.email
                            .slice(0, 22)
                            .concat("...")
                        : complaint?.complainer?.email}
                    </Typography>
                  </Tooltip>
                </div>
              </div>
            </Grid>
            <Grid item>
              <div className="row" style={{ marginTop: 8 }}>
                <div className="col-md-6">
                  <Typography
                    style={{
                      fontSize: 18,
                      fontFamily: "Poppins",
                      color: "#979797",
                      fontWeight: 400,
                    }}
                  >
                    4.&nbsp; &nbsp; Employee ID :{" "}
                  </Typography>
                </div>
                <div className="col-md-6">
                  <Typography
                    style={{
                      fontSize: 18,
                      fontFamily: "Poppins",
                      color: "#000000",
                      fontWeight: 400,
                    }}
                  >
                    {complaint?.complainer?.uniqueId
                      ? complaint?.complainer?.uniqueId
                      : complaint?.complainer?.adminUniqueId}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item style={{ display: "flex", justifyContent: "flex-end" }}>
              {complaint?.image?.length > 1 && (
                <Typography
                  className="pointer btn btn-sm btn-light shadow-sm border"
                  style={{
                    fontSize: 12,
                    fontFamily: "Poppins",
                    color: "#000000",
                    fontWeight: 400,
                    marginTop: 8,
                  }}
                  onClick={() => {
                    setSlideshow(true);
                  }}
                >
                  View All
                </Typography>
              )}
            </Grid>
            <Grid item>
              <div className="row" style={{ marginTop: -5 }}>
                <div className="col-md-6">
                  <Typography
                    style={{
                      fontSize: 18,
                      fontFamily: "Poppins",
                      color: "#979797",
                      fontWeight: 400,
                      marginTop: 10,
                    }}
                  >
                    5.&nbsp; &nbsp; Images Shared :{" "}
                  </Typography>
                </div>
                <div className="d-flex col-md-6">
                  {complaint?.image?.length === 0 && (
                    <Typography
                      style={{
                        fontSize: 18,
                        fontFamily: "Poppins",
                        color: "#000000",
                        fontWeight: 400,
                        marginTop: 10,
                      }}
                    >
                      No Image Added
                    </Typography>
                  )}
                  {complaint?.image?.length > 0 && (
                    <img
                      src={complaint?.image?.[0]}
                      height="60px"
                      width="60px"
                    />
                  )}

                  {complaint?.image?.length > 1 && (
                    <span
                      style={{
                        fontSize: 16,
                        fontFamily: "Poppins",
                        color: "#000000",
                        fontWeight: 400,
                        marginLeft: 10,
                        marginTop: 10,
                      }}
                    >
                      +{complaint?.image?.length - 1} images
                    </span>
                  )}
                </div>
              </div>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default ComplaintDetailsCard;
