import React, { useState, useEffect, useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Paper, Checkbox, Button, Typography } from "@material-ui/core";
import useStyles from "./styles";
import EditIcon from "@mui/icons-material/Edit";
import {
  FlagCircleOutlined,
  InfoOutlined,
  PersonPin,
} from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import {
  ArrowRightAlt,
  AssignmentIndOutlined,
  DeleteOutlined,
} from "@material-ui/icons";
import PriorityContact from "./PriorityContact";
import { isMemberTypeS2Admin } from "../../../GlobalFunctions/GlobalFunctions";
import { Modal } from "antd";
import { ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import * as api from "../../../api";
import { toast } from "react-toastify";
import { resolveErrorMessage } from "../../../services/service";
import { useNavigate, useParams } from "react-router";
import { navigateToComplaintSubSubCategory } from "../../../Navigation/Navigations";
import { AuthContext } from "../../../contextAPI/context";
import PriorityContactPlant from "./PriorityContactPlant";

function SubCategoryTable(props) {
  const classes = useStyles();
  const {
    allSubCategory,
    page,
    setisSubcategoryEdit,
    setsubCategoriEdited,
    setshowEscalationProfile,
    handleGetEscalationProfile,
    setassignToAll,
    isReadWrite,
    subCategoriEdited,
    getComplaintSubCategory,
    user,
    isPlant,
  } = props;
  const { id, categoryName } = useParams();
  const navigate = useNavigate();
  const { authState, authDispatch } = useContext(AuthContext);
  const { plant } = authState;
  const [seePriorityNumbers, setseePriorityNumbers] = useState(false);
  const [loader, setloader] = useState(false);

  const handleDeleteSubCategoryModal = (category, isDeleted) => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to delete ${category?.subcategoryName}?`,
      okText: loader ? (
        <LoadingOutlined style={{ color: "#fff" }} />
      ) : (
        "Confirm"
      ),
      cancelText: "Cancel",
      onOk() {
        handleSubCategoryDelete(category);
      },
    });
  };

  const handleSubCategoryDelete = async (subcategory) => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      const sendData = {
        subComplaintId: subcategory?._id,
      };
      const res = await api.deleteSubCategory(sendData);
      if (res?.data?.success) {
        toast.success("Category Deleted Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
        });

        getComplaintSubCategory();
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      resolveErrorMessage(error);
    }
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                style={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "12px",
                }}
              >
                Sr. No.
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                style={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "12px",
                }}
              >
                Name
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                style={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "12px",
                }}
              >
                Deadline
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                style={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "12px",
                }}
              >
                Assigned Vendor
                {isReadWrite && (
                  <Tooltip
                    title={`Click Assign Vendor to all at once`}
                    placement="top"
                    arrow
                  >
                    <Button
                      size="small"
                      style={{
                        backgroundColor: "#fff",
                        border: "1px solid #6c757d",
                        marginLeft: 4,
                      }}
                      onClick={() => {
                        setassignToAll(true);
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#6c757d",
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: "bold",
                        }}
                      >
                        <AssignmentIndOutlined />
                        Assign All
                      </Typography>
                    </Button>
                  </Tooltip>
                )}
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                style={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "12px",
                }}
              >
                Auto Assign
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                style={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "12px",
                }}
              >
                Auto Escalation
                <Tooltip
                  title={`View Escalation Profiles`}
                  placement="top"
                  arrow
                >
                  <PersonPin
                    style={{
                      color: "#fff",
                      marginLeft: 2,
                      cursor: "pointer",
                      marginTop: -2,
                    }}
                    onClick={() => {
                      handleGetEscalationProfile();
                      setshowEscalationProfile(true);
                    }}
                  />
                </Tooltip>
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                style={{
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "12px",
                }}
              >
                Action
              </TableCell>
              {isMemberTypeS2Admin(user?.role) && (
                <TableCell
                  align="center"
                  className={classes.tableHeaderCell}
                  style={{
                    color: "white",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "12px",
                  }}
                >
                  Priority Contact
                </TableCell>
              )}
              {plant && (
                <TableCell
                  align="center"
                  className={classes.tableHeaderCell}
                  style={{
                    color: "white",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "12px",
                  }}
                >
                  Sub-Subcategory
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {allSubCategory?.map((subcategory, index) => {
              return (
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {(page - 1) * 50 + (index + 1)}.
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {subcategory.subcategoryName}&nbsp;
                    {subcategory?.emergency && (
                      <Tooltip
                        title={`Click to see priority contacts`}
                        placement="top"
                        arrow
                      >
                        <FlagCircleOutlined
                          style={{ color: "#2f45c5", cursor: "pointer" }}
                          onClick={() => {
                            setsubCategoriEdited(subcategory);
                            setseePriorityNumbers(true);
                          }}
                        />
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {subcategory.deadline !== "0"
                      ? `${subcategory.deadline} hours`
                      : "null"}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {subcategory?.vendor?.name
                      ? subcategory?.vendor?.name
                      : "no vendor assigned"}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    <Checkbox
                      checked={subcategory.autoAssign}
                      style={{ color: "#2f45c5", marginTop: -10 }}
                    />
                  </TableCell>{" "}
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    <Checkbox
                      checked={subcategory.autoEscalation}
                      style={{ color: "#2f45c5", marginTop: -10 }}
                    />
                  </TableCell>
                  {isReadWrite && (
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <span
                        className="badge bg-primary"
                        onClick={() => {
                          setsubCategoriEdited(subcategory);
                          setisSubcategoryEdit(true);
                        }}
                        style={{ width: "50px", padding: 8, cursor: "pointer" }}
                      >
                        <EditIcon style={{ fontSize: "12px" }} />
                      </span>
                      {isMemberTypeS2Admin(user?.role) && (
                        <span
                          className="badge bg-danger ms-2"
                          onClick={() => {
                            handleDeleteSubCategoryModal(subcategory, true);
                          }}
                          style={{
                            width: "50px",
                            padding: 8,
                            cursor: "pointer",
                          }}
                        >
                          <DeleteOutlined style={{ fontSize: "12px" }} />
                        </span>
                      )}
                    </TableCell>
                  )}
                  {isMemberTypeS2Admin(user?.role) && (
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <Tooltip
                        title={`Click to see priority contacts`}
                        placement="top"
                        arrow
                      >
                        <InfoOutlined
                          onClick={() => {
                            setsubCategoriEdited(subcategory);
                            setseePriorityNumbers(true);
                          }}
                          style={{ color: "#2f45c5", cursor: "pointer" }}
                        />
                      </Tooltip>
                    </TableCell>
                  )}
                  {plant && (
                    <TableCell align="center" className="pointer">
                      <ArrowRightAlt
                        title="go to subcategory page"
                        style={{ fontSize: 25, cursor: "pointer" }}
                        onClick={() =>
                          navigate(
                            navigateToComplaintSubSubCategory({
                              categoryId: id,
                              categoryName: categoryName,
                              subcategoryId: subcategory._id,
                              subcategoryName: subcategory?.subcategoryName,
                            })
                          )
                        }
                      />
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {seePriorityNumbers && !isPlant && (
        <PriorityContact
          seePriorityNumbers={seePriorityNumbers}
          setseePriorityNumbers={setseePriorityNumbers}
          subcategory={subCategoriEdited}
          getComplaintSubCategory={getComplaintSubCategory}
          user={user}
        />
      )}
      {seePriorityNumbers && isPlant && (
        <PriorityContactPlant
          seePriorityNumbers={seePriorityNumbers}
          setseePriorityNumbers={setseePriorityNumbers}
          user={user}
          subcategory={subCategoriEdited}
          getComplaintSubCategory={getComplaintSubCategory}
        />
      )}
    </>
  );
}

export default SubCategoryTable;
