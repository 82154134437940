import React, { useState, useContext, useEffect } from "react";
import VendorsTable from "./VendorsTable/VendorsTable";
import { Typography, Grid } from "@material-ui/core";
import useStyles from "./styles";
import AddVendor from "./AddVendor/AddVendor";
import { AuthContext } from "../../contextAPI/context";
import Loading from "../../contextAPI/Loading";
import SearchIcon from "@mui/icons-material/Search";
import "./Vendors.css";
import * as api from "../../api";
import { Add, Visibility } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import { handleGetCheckModulePermission } from "../../contextAPI/checkModulesPermission";
import { resolveErrorMessage } from "../../services/service";
import { fetchSocietyId } from "../../contextAPI/fetchSociety";
import { Pagination } from "@mui/material";
import {
  navigateToApproveVendor,
  navigateToDeletedVendor,
} from "../../Navigation/Navigations";

function Vendors() {
  const classes = useStyles();
  const { authState } = useContext(AuthContext);
  const { usermoduleswithpermissions } = authState;
  const [addVendorModal, setaddVendorModal] = useState(false);
  const [search, setsearch] = useState("");
  const [FilteredVendor, setFilteredVendor] = useState([]);
  const [allVendor, setAllVendor] = useState([]);
  const navigate = useNavigate();
  const [isReadWrite, setIsReadWrite] = useState(false);
  const [page, setPage] = React.useState(1);
  const [count, setCount] = React.useState(0);
  const [loader, setloader] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");

  useEffect(async () => {
    if (usermoduleswithpermissions?.length) {
      setIsReadWrite(
        await handleGetCheckModulePermission(
          usermoduleswithpermissions,
          "Vendor"
        )
      );
    }
  }, [usermoduleswithpermissions]);

  useEffect(() => {
    if (search !== "") {
      handleSearchVendor();
    }
  }, [search]);

  const handleSearchVendor = async () => {
    let temp = [];
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      const res = await api.searchVendor({ params: { key: search } });
      if (res?.data?.success) {
        temp.push(res.data.vendor);
      }
      setFilteredVendor(temp[0]);
      setloader(false);
    } catch (error) {
      setloader(false);
      resolveErrorMessage(error);
    }
  };

  const handlePageChange = async (event, value) => {
    setPage(value);
  };

  const handleGetVendor = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      let sendData = {
        pageLimit: 50,
        societyId: fetchSocietyId(),
        pageNumber: page,
        searchKey: search,
      };
      const res = await api.getAllVendor(sendData);
      if (res?.data?.success) {
        setAllVendor(res?.data?.vendor?.vendor);
        setCount(res?.data?.vendor?.totalPage);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  useEffect(() => {
    handleGetVendor();
  }, [search, page]);

  return (
    <div
      className={classes.root}
      style={{
        opacity: loader ? 0.4 : "",
        pointerEvents: loader ? "none" : "",
        overflow: loader ? "hidden" : "auto",
        height: "100vh",
      }}
    >
      <main className={classes.content}>
        <div className={classes.toolbar} />

        <Grid item lg={12} sm={12} xs={12}>
          <div class="card" style={{ borderRadius: 6 }}>
            <div class="card-body">
              <Grid
                container
                style={{
                  justifyContent: "space-between",
                }}
              >
                <Grid>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 22,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                    }}
                  >
                    Vendor Management
                  </Typography>
                </Grid>

                <Grid
                  container
                  item
                  lg={6}
                  style={{ justifyContent: "flex-end", marginRight: 10 }}
                >
                  <div
                    class="card"
                    style={{
                      height: 30,
                      boxShadow: "0 3px 6px #4a6078",
                      width: "155px",
                      backgroundColor: "#2f45c5",
                      color: "#ffffff",
                      cursor: "pointer",
                      marginRight: 10,
                    }}
                    onClick={() => {
                      navigate(navigateToDeletedVendor({}));
                    }}
                  >
                    <div class="card-body ">
                      <p
                        class="card-text"
                        style={{
                          fontSize: "11px",
                          fontWeight: "bold",
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "-12px",
                          marginBottom: "20px",
                          textAlign: "center",
                        }}
                      >
                        <Visibility
                          style={{ marginRight: 5, fontSize: "15px" }}
                        />
                        &nbsp;Deleted Vendors
                      </p>
                    </div>
                  </div>
                  {isReadWrite && (
                    <div
                      class="card"
                      style={{
                        height: 30,
                        boxShadow: "0 3px 6px #4a6078",
                        width: "155px",
                        backgroundColor: "#2f45c5",
                        color: "#ffffff",
                        cursor: "pointer",
                        textAlign: "center",
                      }}
                      onClick={() => {
                        navigate(navigateToApproveVendor());
                      }}
                    >
                      <div class="card-body ">
                        <p
                          class="card-text"
                          style={{
                            fontSize: "11px",
                            fontWeight: "bold",
                            fontFamily: "Montserrat, sans-serif",
                            marginTop: "-12px",
                            marginBottom: "20px",
                            textAlign: "center",
                          }}
                        >
                          <Add style={{ marginRight: 5, fontSize: "15px" }} />
                          &nbsp;Approve Vendor
                        </p>
                      </div>
                    </div>
                  )}
                  {isReadWrite && (
                    <div
                      class="card ms-2"
                      style={{
                        height: 30,
                        boxShadow: "0 3px 6px #4a6078",
                        width: "155px",
                        backgroundColor: "#2f45c5",
                        color: "#ffffff",
                        cursor: "pointer",
                        textAlign: "center",
                      }}
                      onClick={() => {
                        setaddVendorModal(true);
                      }}
                    >
                      <div class="card-body ">
                        <p
                          class="card-text"
                          style={{
                            fontSize: "11px",
                            fontWeight: "bold",
                            fontFamily: "Montserrat, sans-serif",
                            marginTop: "-12px",
                            marginBottom: "20px",
                            textAlign: "center",
                          }}
                        >
                          <Add style={{ marginRight: 5, fontSize: "15px" }} />
                          &nbsp;Add Vendor
                        </p>
                      </div>
                    </div>
                  )}
                </Grid>
              </Grid>

              <Grid
                container
                style={{ marginTop: 20, justifyContent: "flex-end" }}
              >
                <Grid>
                  <div class="box">
                    <input
                      type="text"
                      placeholder="search..."
                      onChange={(e) => {
                        setsearch(e.target.value);
                        setPage(1);
                      }}
                    />
                    <div className="searchIcon">
                      <SearchIcon onClick={handleSearchVendor} />
                    </div>
                  </div>
                </Grid>
                {/* 
                <div
                  class="card"
                  style={{
                    height: 30,
                    boxShadow: "0 3px 6px #4a6078",
                    width: "155px",
                    backgroundColor: "#2f45c5",
                    color: "#ffffff",
                    cursor: "pointer",
                    textAlign: "center",
                    marginTop: 5,
                  }}
                  onClick={() => {
                    navigate("/allvendorsdata");
                  }}
                >
                  <div class="card-body ">
                    <p
                      class="card-text"
                      style={{
                        fontSize: "11px",
                        fontWeight: "bold",
                        fontFamily: "Montserrat, sans-serif",
                        marginTop: "-12px",
                        marginBottom: "20px",
                        textAlign: "center",
                      }}
                    >
                      <DataThresholdingRoundedIcon
                        style={{ marginTop: -3, fontSize: 17 }}
                      />
                      &nbsp;&nbsp;Vendors Reports
                    </p>
                  </div>
                </div> */}
              </Grid>

              <Grid style={{ marginTop: 16, marginRight: 20 }}>
                <VendorsTable
                  vendors={allVendor}
                  // {
                  //   search === ""
                  //     ? vendors
                  //     : search !== ""
                  //     ? FilteredVendor
                  //     : FilteredVendor
                  // }
                  type="all"
                  loader={loader}
                  isReadWrite={isReadWrite}
                  page={page}
                  setPage={setPage}
                  handleGetVendor={handleGetVendor}
                />
              </Grid>
              {allVendor?.length > 0 && (
                <Grid
                  container
                  style={{
                    justifyContent: "center",
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                >
                  <Pagination
                    className={classes.pagination}
                    count={count}
                    color="primary"
                    page={page}
                    onChange={handlePageChange}
                  />
                </Grid>
              )}
              <AddVendor
                addVendorModal={addVendorModal}
                setaddVendorModal={setaddVendorModal}
                handleGetVendor={handleGetVendor}
              />
            </div>
          </div>
        </Grid>
      </main>
      {loader && <Loading />}
    </div>
  );
}

export default Vendors;
