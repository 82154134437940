import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Divider,
  IconButton,
  Paper,
  Switch,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Modal from "@mui/material/Modal";
import ChooseVendorDropDown from "../../../../../GlobalComponents/DropDowns/ChooseVendorDropDown";
import { ComplaintUpdateProcessRoutes } from "../../../../../api/Routes";
import ApiCall from "../../../../../api/ApiCall";
import { fetchSocietyId } from "../../../../../contextAPI/fetchSociety";
import { toast } from "react-toastify";
import ChooseSubCategoryDropDown from "../../../../../GlobalComponents/DropDowns/ChooseSubCategoryDropDown";
import ChooseCategoryDropDown from "../../../../../GlobalComponents/DropDowns/ChooseCategoryDropDown";

const AssignVendorModal = (props) => {
  const {
    selectProcess,
    complaint,
    classes,
    setselectProcess,
    user,
    handlegetComplaintById,
    setupdateProcecssModal,
  } = props;
  const [vendor, setVendor] = useState([]);
  const [searchKey, setsearchKey] = useState("");
  const [deadLineInDays, setdeadLineInDays] = useState("");
  const [deadLineInHours, setdeadLineInHours] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [loader, setloader] = useState(false);
  const [complaintAutoEscalation, setcomplaintAutoEscalation] = useState(false);
  const [priority, setpriority] = useState(false);
  const [category, setcategory] = useState("");
  const [subcategory, setsubcategory] = useState("Select Subcategory");

  useEffect(() => {
    if (complaint) {
      setcomplaintAutoEscalation(complaint?.autoEscalate);
      setpriority(complaint?.priority === "high" ? true : false);
      setVendor(complaint?.vendor);
    }
  }, [complaint]);

  const callingApi = () => {
    setloader(true);
    seterrorMessage("");
    setTimeout(() => {
      setloader(false);
    }, 40000);
  };

  const handleReset = () => {
    handlegetComplaintById();
    setupdateProcecssModal(false);
    setselectProcess("Select Process");
    setVendor([]);
    setdeadLineInDays("");
    seterrorMessage("");
    setloader(false);
  };

  const handleAssignVendor = async () => {
    callingApi();
    let temp =
      24 * parseInt(deadLineInDays === "" ? 0 : deadLineInDays) +
      parseInt(deadLineInHours === "" ? 0 : deadLineInHours);
    seterrorMessage("");
    if (!vendor || vendor?.length === 0) {
      seterrorMessage("Please Choose Vendor");
      setloader(false);
    } else if (temp <= "0") {
      seterrorMessage("Please add a valid Deadline");
      setloader(false);
    } else if (category === "" && selectProcess === "Re-assign Vendor") {
      seterrorMessage("Please add a Category");
      setloader(false);
    } else if (
      subcategory === "Select Subcategory" &&
      selectProcess === "Re-assign Vendor"
    ) {
      seterrorMessage("Please add a SubCategory");
      setloader(false);
    } else {
      const sendData = {
        complaintId: complaint?._id,
        vendorId: vendor?._id,
        highPriority: priority,
        autoEscalate: complaintAutoEscalation,
        societyId: fetchSocietyId(),
        deadline: temp.toString(),
        complaintcategoryId:
          selectProcess === "Assign Vendor"
            ? complaint?.category?._id
            : category?._id,
        subCategoryId:
          selectProcess === "Assign Vendor"
            ? complaint?.subcomplaintCategory?._id
            : subcategory?._id,
        reassign: selectProcess === "Assign Vendor" ? false : true,
      };
      const sendData2 = {
        complaintId: complaint?._id,
        complainerId: complaint?.complainer?._id,
        complaintLifeCycle:
          selectProcess === "Assign Vendor"
            ? "Assign vendor"
            : "Reassign Vendor",
        vendorId: vendor?._id,
        adminId: user?._id,
        deadline: temp.toString(),
      };
      const res = await ApiCall(
        "post",
        ComplaintUpdateProcessRoutes.assignOrReassignComplaint,
        sendData
      );
      if (res?.success) {
        const res2 = await ApiCall(
          "post",
          ComplaintUpdateProcessRoutes.complaintLifeCycle,
          sendData2
        );
        handleReset();
        toast.success(
          selectProcess === "Assign Vendor"
            ? "Vendor Assigned Successfully"
            : "Vendor Reassign Successfully"
        );
      } else {
        setloader(false);
        seterrorMessage(res.error);
      }
    }
  };
  useEffect(() => {
    seterrorMessage("");
    setsubcategory("Select Subcategory");
  }, [category]);

  return (
    <Modal
      open={
        selectProcess === "Assign Vendor" ||
        selectProcess === "Re-assign Vendor"
      }
      style={{ pointerEvents: loader ? "none" : "" }}
    >
      <Paper
        style={{
          backgroundColor: "#fff",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
          minWidth: "400px",
          maxWidth: "40vw",
        }}
        className={classes.ModalView}
      >
        <div className="col-sm-12 d-flex text-align-center justify-content-between">
          <span
            style={{
              fontFamily: "Montserrat, sans-serif",
            }}
            className="fw-bold fs-6"
          >
            {selectProcess === "Assign Vendor"
              ? "Assign Vendor"
              : "Reassign Vendor"}
          </span>

          <IconButton
            onClick={() => {
              setselectProcess("Select Process");
              seterrorMessage("");
            }}
          >
            <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
          </IconButton>
        </div>
        <Divider
          style={{
            marginTop: 0,
            marginBottom: 10,
            backgroundColor: "#000",
          }}
        />
        <span
          style={{
            color: "#283f59",
            fontWeight: "600",
            fontFamily: "Montserrat, sans-serif",
          }}
          className="d-flex text-align-center ms-2 mt-1 fs-6"
        >
          Choose Vendor
        </span>
        <div className="col-sm-12 d-flex justify-content-center mb-2 mt-2">
          <ChooseVendorDropDown
            vendor={vendor}
            setVendor={setVendor}
            setsearchKey={setsearchKey}
            searchKey={searchKey}
          />
        </div>
        <div className="col-sm-12 d-flex justify-content-center mt-3">
          <div className="col-sm-6">
            <span
              style={{
                color: "#283f59",
                fontWeight: "600",
                fontFamily: "Montserrat, sans-serif",
              }}
              className="ms-2 fs-6"
            >
              Deadline in Days
            </span>
          </div>
          <div className="col-sm-6">
            <span
              style={{
                color: "#283f59",
                fontWeight: "600",
                fontFamily: "Montserrat, sans-serif",
              }}
              className="ms-2 fs-6"
            >
              Deadline in Hours
            </span>
          </div>
        </div>
        <div className="col-sm-12 d-flex justify-content-center mt-1">
          <div className="col-sm-6">
            <input
              type="number"
              style={{
                borderRadius: 3,
                padding: 10,
                width: "98%",
                border: "0.5px solid #010101",
                marginBottom: 8,
                outline: 0,
              }}
              onChange={(event) => setdeadLineInDays(event.target.value)}
              placeholder="Enter Days"
              value={deadLineInDays}
            />
          </div>
          <div className="col-sm-6">
            <input
              type="number"
              style={{
                borderRadius: 3,
                padding: 10,
                width: "98%",
                border: "0.5px solid #010101",
                marginBottom: 8,
                outline: 0,
              }}
              onChange={(event) => setdeadLineInHours(event.target.value)}
              placeholder="Enter Hours"
              value={deadLineInHours}
            />
          </div>
        </div>
        <span
          style={{
            fontWeight: "bold",
            fontFamily: "Montserrat, sans-serif",
            color: "#283f59",
            fontSize: "13px",
          }}
        >
          Total Deadline Hours : &nbsp;
          {24 * parseInt(deadLineInDays === "" ? 0 : deadLineInDays) +
            parseInt(deadLineInHours === "" ? 0 : deadLineInHours)}{" "}
          Hours
        </span>
        {selectProcess === "Re-assign Vendor" && (
          <div className="col-sm-12 d-flex mt-3">
            <div className="col-sm-6">
              <span
                style={{
                  color: "#283f59",
                  fontWeight: "600",
                  fontFamily: "Montserrat, sans-serif",
                }}
                className="ms-3 fs-6"
              >
                Category
              </span>
            </div>
            <div className="col-sm-6">
              <span
                style={{
                  color: "#283f59",
                  fontWeight: "600",
                  fontFamily: "Montserrat, sans-serif",
                }}
                className="ms-3 fs-6"
              >
                SubCategory
              </span>
            </div>
          </div>
        )}
        {selectProcess === "Re-assign Vendor" && (
          <div className="col-sm-12 d-flex ms-2">
            <div className="col-sm-6 mt-2">
              <ChooseCategoryDropDown
                setCategory={setcategory}
                category={category}
                isMultiple={false}
                searchKey={searchKey}
                setsearchKey={setsearchKey}
              />
            </div>
            <div className="col-sm-6 mt-2">
              <ChooseSubCategoryDropDown
                setSubCategory={setsubcategory}
                subCategory={subcategory}
                isMultiple={false}
                searchKey={searchKey}
                setsearchKey={setsearchKey}
                category={category}
              />
            </div>
          </div>
        )}

        <div className="col-sm-12 mt-3 d-flex justify-content-center">
          <span
            style={{
              color: "#283f59",
            }}
            className="mt-2 fw-bold fs-6"
          >
            Auto-Escalation
          </span>
          <Checkbox
            checked={complaintAutoEscalation}
            onChange={() => {
              setcomplaintAutoEscalation(!complaintAutoEscalation);
            }}
            style={{ color: "#4b8edb" }}
          />
          <span
            style={{
              color: "#283f59",
            }}
            className="mt-2 fw-bold fs-6 ms-3"
          >
            High Priority
          </span>
          <Switch
            color="primary"
            checked={priority}
            onChange={() => {
              setpriority(!priority);
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
        {errorMessage && (
          <span className="col-sm-12 d-flex justify-content-center text-align-center text-danger fw-bold fs-6">
            {errorMessage}
          </span>
        )}
        <div className="col-sm-12 d-flex justify-content-center">
          <div
            class="card"
            style={{
              height: 35,
              boxShadow: "0 3px 6px #7D858D",
              width: "120px",
              backgroundColor: "#2f45c5",
              color: "#ffffff",
              cursor: "pointer",
              marginLeft: 5,
              marginTop: 20,
            }}
            onClick={handleAssignVendor}
          >
            <div class="card-body ">
              <p
                class="card-text"
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: "-10px",
                  textAlign: "center",
                }}
              >
                {!loader ? "SAVE" : `Adding...`}
              </p>
            </div>
          </div>
        </div>
      </Paper>
    </Modal>
  );
};

export default AssignVendorModal;
