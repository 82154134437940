import React, { useContext, useEffect, useState } from "react";
import {
  EmailOutlined,
  ErrorOutline,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import "../entrypoint.css";
import SociohoodLogo from "../../assets/SociohoodLogo.png";
import axios from "axios";
import * as api from "../../api";
import {
  LOGINUSER,
  VERSIONHANDLE,
} from "../../contextAPI/actions/auth.actions";
import { useNavigate } from "react-router-dom";
import { resolveErrorMessage } from "../../services/service";
import { toast } from "react-toastify";
import Reset from "../Reset/Reset";
import { encrypData } from "../../utils/encryption";
import { AuthContext } from "../../contextAPI/context";
import PageLaoder from "../../GlobalComponents/Loaders/PageLaoder";
import PendingApprovalModal from "../PendingApprovalModal";

const Login = () => {
  const navigate = useNavigate();
  const { authState, authDispatch } = useContext(AuthContext);
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const [isEmailValid, setisEmailValid] = useState(true);
  const [loader, setLoader] = useState("");
  const [isVisible, setisVisible] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [isResetPassword, setisResetPassword] = useState(false);
  const [isPendingApproval, setisPendingApproval] = useState(false);

  const handlePasswordVisibility = () => {
    setisVisible(!isVisible);
  };
  const validateEmail = (e) => {
    if (e.target?.value && e.target.value.match(isValidEmail)) {
      setisEmailValid(true);
      setemail(e.target.value);
    } else {
      setemail(e.target.value);
      setisEmailValid(false);
    }
  };

  const startApiCall = () => {
    seterrorMessage("");
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 50000);
  };
  const resetData = () => {
    setemail("");
    setpassword("");
    seterrorMessage("");
    setLoader(false);
  };

  const handleLogin = async (e) => {
    try {
      e.preventDefault();
      startApiCall();
      const sendData = {
        password,
        email: email.trim().toLowerCase(),
      };
      const res = await api.managementLogin(sendData);
      if (res?.data?.success) {
        authDispatch({
          type: LOGINUSER,
          payload: res.data.data,
        });
        window.location.href = "/dashboard";
        localStorage.setItem("startdate", "");
        localStorage.setItem("enddate", "");
        localStorage.setItem("complaintCategory", "Choose Category");
        localStorage.setItem("complaintSubCategory", "Choose Subcategory");
        // setLoader(false);
        toast.success("Login Successfully");
      }
    } catch (error) {
      setLoader(false);
      seterrorMessage(resolveErrorMessage(error));
      if (resolveErrorMessage(error) === "you are not approved yet") {
        setisPendingApproval(true);
      }
    }
  };

  const handleVersion = async () => {
    try {
      const res = await axios.post(
        `https://versionprod.sociohood.com/api/version/get-version-dashboard`,
        {
          platform: "dashboard",
        }
      );
      let domainName = encrypData(res.data.server.server.domainName);
      let version = encrypData(res.data.server.version);
      let isMaintenance = res.data.server.isMaintenance;
      localStorage.setItem("domainName", domainName);
      localStorage.setItem("version", version);
      localStorage.setItem("isMaintenance", isMaintenance);
      authDispatch({
        type: VERSIONHANDLE,
        payload: isMaintenance,
      });
    } catch (errors) {
      console.error(errors);
    }
  };

  useEffect(() => {
    handleVersion();
  }, []);

  return (
    <>
      <div
        class="login-page-container"
        style={{
          opacity: loader ? 0.6 : "",
          pointerEvents: loader ? "none" : "",
        }}
      >
        <div class="detalis-main d-flex flex-column align-items-center justify-content-center">
          <div class="login-main">
            <img
              class="logo"
              src={SociohoodLogo}
              alt="Sociohood Technologies Pvt. Ltd."
            />

            <p
              style={{ fontFamily: "Montserrat, sans-serif", fontSize: "20px" }}
            >
              Welcome to Sociohood
            </p>
            {errorMessage === "" ? (
              <p
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  color: "#AAA",
                  fontSize: "13px",
                  margin: 0,
                  textTransform: "none",
                  marginBottom: -6,
                }}
              >
                &nbsp;&nbsp; Sign In to continue
              </p>
            ) : (
              <p
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  color: "#e34949",
                  fontSize: "13px",
                  margin: 0,
                  textTransform: "none",
                }}
              >
                <ErrorOutline />
                &nbsp;&nbsp;
                {errorMessage}
              </p>
            )}
          </div>
          <form onSubmit={handleLogin}>
            <div class="form-detalis">
              <div class="input-main">
                <EmailOutlined style={{ color: "#303f9f" }} />

                <div class="be-main"></div>
                <div class="input-box">
                  <input
                    type="email"
                    class="input"
                    required
                    placeholder="Email Address"
                    onChange={(e) => {
                      validateEmail(e);
                    }}
                    value={email}
                  />
                </div>

                {isEmailValid ? (
                  <svg
                    class="icon-continue"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg>
                ) : (
                  <>
                    <svg
                      style={{ color: "red" }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-exclamation-triangle"
                      viewBox="0 0 16 16"
                    >
                      {" "}
                      <path
                        d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
                        fill="red"
                      ></path>{" "}
                      <path
                        d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"
                        fill="red"
                      ></path>{" "}
                    </svg>
                  </>
                )}
              </div>
              <div class="input-main" style={{ marginTop: 15 }}>
                {isVisible ? (
                  <VisibilityOutlined
                    onClick={handlePasswordVisibility}
                    style={{ color: "#303f9f", cursor: "pointer" }}
                  />
                ) : (
                  <VisibilityOffOutlined
                    onClick={handlePasswordVisibility}
                    style={{ color: "#303f9f", cursor: "pointer" }}
                  />
                )}
                <div class="be-main" style={{ marginLeft: "17px" }}></div>
                <div class="input-box">
                  <input
                    type={isVisible ? "text" : "password"}
                    required
                    class="input"
                    placeholder="Enter Password"
                    onChange={(e) => {
                      setpassword(e.target.value);
                    }}
                    style={{ marginLeft: "6px" }}
                    value={password}
                  />
                </div>
                <p
                  onClick={() => {
                    setisResetPassword(true);
                  }}
                  class="forgot-password"
                >
                  Reset Password ?
                </p>
              </div>
            </div>

            <button
              type="submit"
              class="continue"
              disabled={loader}
              style={{ opacity: loader ? 0.8 : "" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    fontSize: "16px",
                    marginTop: 10,
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "bold",
                  }}
                >
                  {loader ? "Loging in..." : "LOGIN"}
                </p>
              </div>
            </button>
          </form>

          <p
            class="signup-text"
            style={{ fontFamily: "Montserrat, sans-serif" }}
          >
            Don't have an account?{" "}
            <button
              style={{
                color: "#303f9f",
                cursor: "pointer",
                border: "none",
                fontWeight: "bold",
                backgroundColor: "#f7f8ff",
              }}
              onClick={() => {
                navigate("/signup");
              }}
            >
              Sign up
            </button>
          </p>
        </div>
        {isResetPassword && (
          <Reset
            isResetPassword={isResetPassword}
            setisResetPassword={setisResetPassword}
          />
        )}
        {isPendingApproval && (
          <PendingApprovalModal
            isPendingApproval={isPendingApproval}
            setisPendingApproval={setisPendingApproval}
          />
        )}
      </div>
      {loader && <PageLaoder />}
    </>
  );
};
export default Login;
